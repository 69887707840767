<template>
  <head>
    <title>Arif Burak Demiray</title>
    <meta name="description" content="This is my personel website that connects all my platforms for a developer like me.">
    <meta name="keywords" content="backend developer, developer, arif burak demiray, arif, burak, demiray">
  </head>

  <Home /> 
</template>

<script>
import Home from './pages/Home.vue';
export default {
  name: 'App',
  components: {
    Home
  }
};
</script>
